
//for PROD: 
//const stripePromise = loadStripe('pk_live_51IAbUnFEfwu9Vl237qwXEjSFm7y4O0iKsZWWamNZOVpye5XjCoaQyGOblWRZh20CW7rpJ60akG4Pvp6VsC8cmaSF00wKvFDvEr');
//for TEST:
// const stripePromise = loadStripe('pk_test_51Pyd26FMJLQLKujTqyFrcihstqly9qFhJrsgTKSw5wriMnaZMNEh01sncXdl1houdHEvRvkc3CYsJCr3Lc95OE1Y00KXaJXZ4z');

// LearningFocusPage.js
import React, { useEffect, useState, useRef, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { auth, firestore, analytics } from './firebase'; // Removed 'functions' as it's not directly used
import { onAuthStateChanged } from 'firebase/auth';
import { getDoc, doc, setDoc, serverTimestamp, collection, query, where, getDocs, onSnapshot } from 'firebase/firestore';
import { FaPen, FaTrash, FaTimes } from 'react-icons/fa'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons'; 
import { loadStripe } from '@stripe/stripe-js';
import ScreenNameContext from './ScreenNameContext';
import { logEvent } from 'firebase/analytics'; // Ensure logEvent is imported
import { getDeviceId } from './utils/device';
import PromoCodeModal from './components/PromoCodeModal';
import { getFunctions, httpsCallable } from 'firebase/functions'; 
import { v4 as uuidv4 } from 'uuid'; // Ensure uuidv4 is imported if needed
import LoadingSpinner from './LoadingSpinner'; 
//import AlertMessageModal from './components/AlertMessageModal'; 
import { useAlert } from './contexts/AlertContext';

function LearningFocusPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const selectedGoal = location.state?.selectedGoal || null; // Get selected goal if passed
  const [user, setUser] = useState(null);
  const [authInitialized, setAuthInitialized] = useState(false);
  const [learningGoal, setLearningGoal] = useState('');
  const [evaluationScore, setEvaluationScore] = useState(null);
  const [feedback, setFeedback] = useState('');
  const [learningPlan, setLearningPlan] = useState([]);
  const [enhancedLearningPlan, setEnhancedLearningPlan] = useState([]);  // New state for enhanced learning plan
  const [matchedQuizzes, setMatchedQuizzes] = useState([]);

  const pollingIntervalRef = useRef(null); 
  const [pollingIntervalId, setPollingIntervalId] = useState(null);
  const [isPolling, setIsPolling] = useState(false);
  const [pollError, setPollError] = useState(null);
  const [retryAvailable, setRetryAvailable] = useState(false);

  const [quizGenerationStatus, setQuizGenerationStatus] = useState(null); 
  const [quizId, setQuizId] = useState(''); 
  const [learningGoalHistory, setLearningGoalHistory] = useState([]);  // New state for history
  const [userQuizzes, setUserQuizzes] = useState([]); 
  const [isLoading, setIsLoading] = useState(false);
  const [isEvaluationComplete, setIsEvaluationComplete] = useState(false);
  
  const [promoCode, setPromoCode] = useState('');
  const [showPromoCodeModal, setShowPromoCodeModal] = useState(false);
  const [promoCodeMessage, setPromoCodeMessage] = useState('');
  const [promoCodeError, setPromoCodeError] = useState('');
  const [isRedeeming, setIsRedeeming] = useState(false);
  const [usePromoCode, setUsePromoCode] = useState(false);
  const { openAlert, closeAlert } = useAlert();
  const { screenName } = useContext(ScreenNameContext);

  
  // Initialize Firebase Functions
  const functions = getFunctions(undefined, 'us-central1');
  const redeemPromoCodeFunction = httpsCallable(functions, 'redeemPromoCode');

  const stripePromise = loadStripe('pk_live_51IAbUnFEfwu9Vl237qwXEjSFm7y4O0iKsZWWamNZOVpye5XjCoaQyGOblWRZh20CW7rpJ60akG4Pvp6VsC8cmaSF00wKvFDvEr');
  
  const hasLoadedFromHistory = useRef(false); // To track if we loaded from history
  const quizGenerationStatusRef = useRef(quizGenerationStatus);
  const learningPlanRef = useRef(learningPlan);
  const timeoutIdRef = useRef(null);

  useEffect(() => {
    learningPlanRef.current = learningPlan;
  }, [learningPlan]);

  useEffect(() => {
    quizGenerationStatusRef.current = quizGenerationStatus;
  }, [quizGenerationStatus]);

  // Clear pollError when quizGenerationStatus is 'Completed'
  useEffect(() => {
    if (quizGenerationStatus === 'Completed') {
      setPollError(null);
      setRetryAvailable(false);
    }
  }, [quizGenerationStatus]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setAuthInitialized(true);
    });

    return () => unsubscribe();
  }, []);

  

  // fetchLearningData to use onSnapshot
  const fetchLearningData = () => {
    if (!authInitialized) {
      // Wait until auth is initialized
      return;
    }
  
    if (!user) {
      alert('You must be logged in to access this page.');
      navigate('/');
  
      // Log unauthenticated access attempt
      if (analytics) {
        logEvent(analytics, 'unauthenticated_access_attempt', { from_page: '/learning-focus' });
      }
  
      return;
    }
    const userUid = user.uid;
  
    const userDocRef = doc(firestore, 'learningGoals', userUid);
  
    const unsubscribe = onSnapshot(userDocRef, (docSnap) => {
      if (docSnap.exists()) {
        const data = docSnap.data();
  
        // Log learning data fetch
        if (analytics) {
          logEvent(analytics, 'learning_data_fetched', { user_uid: userUid });
        }
  
        // Prioritize loading the selected goal from history, if passed
        if (selectedGoal && data.learningGoalHistory && !hasLoadedFromHistory.current) {
          const matchingHistory = data.learningGoalHistory.find(
            (historyItem) => historyItem.learningGoal === selectedGoal
          );
  
          if (matchingHistory) {
            console.log("Loading from history: ", selectedGoal);
            // Load the matched learning plan from history
            setLearningGoal(matchingHistory.learningGoal);
            setLearningPlan(parseLearningPlan(matchingHistory.learningPlan));
            setEnhancedLearningPlan(matchingHistory.enhancedLearningPlan || []);
            setEvaluationScore(matchingHistory.evaluationScore);
            setFeedback(matchingHistory.feedback);
            setQuizGenerationStatus(matchingHistory.quizGenerationStatus);
            setQuizId(matchingHistory.quizId);
            hasLoadedFromHistory.current = true; // Mark that history was loaded
  
            // Log loading from history
            if (analytics) {
              logEvent(analytics, 'learning_goal_loaded_from_history', { learning_goal: matchingHistory.learningGoal });
            }
  
            return; // Exit early, since we're loading from history
          }
        }
  
        // If no history item is found or no selectedGoal is passed, load the current learning goal from Firestore
        const currentLearningGoal = data.learningGoal || ''; // was 'No learning goal'
        console.log("docSnap exists - currentLearningGoal: " + currentLearningGoal);
        setLearningGoal(currentLearningGoal); // This ensures learningGoal is set
  
        // Update evaluationScore and feedback if available
        if (data.evaluationScore !== undefined && data.evaluationScore !== null) {
          const evalScore = Number(data.evaluationScore);
          if (!isNaN(evalScore)) {
            setEvaluationScore(evalScore);
            setFeedback(data.feedback);
            setIsEvaluationComplete(true);
          } else {
            setIsEvaluationComplete(false);
          }
        } else {
          setIsEvaluationComplete(false);
        }
  
        // Set learning goal history if it exists
        if (data.learningGoalHistory) {
          setLearningGoalHistory(data.learningGoalHistory);
        }
  
        // Check if quiz generation is completed or in progress
        if (data.quizGenerationStatus) {
          console.log("Set quizGenerationStatus to:", data.quizGenerationStatus);
          setQuizGenerationStatus(data.quizGenerationStatus);
        }
  
        if (data.quizId) {
          setQuizId(data.quizId);
        }
  
        // Parsing learning plan string
        const parsedLearningPlan = parseLearningPlan(data.learningPlan || '');
        setLearningPlan(parsedLearningPlan);
  
        // Set enhanced learning plan if exists
        if (data.enhancedLearningPlan) {
          setEnhancedLearningPlan(data.enhancedLearningPlan);
        }
  
        // Handle matched quizzes, either from state or Firestore
        if (location.state && location.state.matchedQuizzes) {
          setMatchedQuizzes(location.state.matchedQuizzes);
        } else if (data.matchedQuizzes) {
          setMatchedQuizzes(data.matchedQuizzes);
        }
  
        handleLearningGoalChange(currentLearningGoal);
      }
    });
  
    return unsubscribe;
  };
  
  // Use the updated fetchLearningData in useEffect
  useEffect(() => {
    const unsubscribe = fetchLearningData();
  
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [navigate, location, selectedGoal, analytics, user, authInitialized]);

  const handleGenerateQuiz = async () => {
    // Log quiz generation attempt
    if (analytics) {
      logEvent(analytics, 'quiz_generation_attempt', { user_uid: auth.currentUser.uid });
    }

    setIsLoading(true); // Start loading
    const stripe = await stripePromise;
    const currentUser = auth.currentUser;
    const userUid = currentUser.uid;
  
    try {
      if (usePromoCode) {
        // Redeem promo code and generate quiz without Stripe
        const deviceId = getDeviceId(); // Retrieve device ID

        // Call the redeemPromoCode Cloud Function using the imported function
        const result = await redeemPromoCodeFunction({ code: promoCode, deviceId });

        if (result.data.success) {
          // Handle success (assume promo code grants free quiz generation)
          setUsePromoCode(false);
          setPromoCode('');
          setPromoCodeMessage('Promo code applied successfully! Generating your quiz...');
          setPromoCodeError('');

          // Optionally, refresh user data to reflect the redeemed benefit
          await fetchLearningData();

          // Navigate to Quiz Generation Progress Page
          navigate('/quiz-generation-progress', { state: { source: 'promoCode' } });

          // Log quiz generation via promo code
          if (analytics) {
            logEvent(analytics, 'quiz_generation_via_promo_code', { user_uid: userUid, promo_code: promoCode });
          }
        } else {
          // Handle unexpected success response structure
          setPromoCodeError('Unexpected response from server.');
          setPromoCodeMessage('');
        }

      } else {
        // Existing Stripe payment flow
        const response = await fetch('https://us-central1-quiznect.cloudfunctions.net/createCheckoutSession', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ userUid }),
        });
    
        const { id } = await response.json();
    
        const { error } = await stripe.redirectToCheckout({ sessionId: id });
        if (error) {
          console.error('Stripe checkout error:', error);
          setIsLoading(false); // Stop loading on error

          // Log quiz generation failure
          if (analytics) {
            logEvent(analytics, 'quiz_generation_failure', { error_code: error.code, error_message: error.message });
          }
        } else {
          // Log quiz generation success (if applicable after redirect)
          if (analytics) {
            logEvent(analytics, 'quiz_generation_success', { user_uid: userUid });
          }
        }
      }
    } catch (error) {
      console.error('Error in handleGenerateQuiz:', error);
      setIsLoading(false); // Stop loading on error

      // Log quiz generation error
      if (analytics) {
        logEvent(analytics, 'quiz_generation_error', { error_code: error.code, error_message: error.message });
      }
    }
  };

  function createPromoPurchaseID(timestamp, userUid, learningGoal, promoCode = null) {
    const formattedTimestamp = timestamp.toISOString().replace(/[-:T]/g, '').slice(0, 14); // Remove special characters and limit to 14 chars
    const normalizedLearningGoal = normalizeString2(learningGoal);
  
    // Generate a random string of 4 alphanumeric characters
    const randomString = Math.random().toString(36).substring(2, 6);
  
    // Determine the prefix based on whether a promo code is used
    let prefix = "QGen";
    if (promoCode) {
      const normalizedPromoCode = normalizeString2(promoCode);
      prefix = `PromoCode-${normalizedPromoCode}`;
    }
  
    // Construct purchaseId
    const purchaseId = `${formattedTimestamp}_${prefix}_${userUid}_${normalizedLearningGoal}_${randomString}`;
  
    // Limit to 256 characters
    return purchaseId.length <= 256 ? purchaseId : purchaseId.slice(0, 256);
  }
  
  function normalizeString2(str) {
    return str
      .toLowerCase()            // Convert to lowercase
      .replace(/\s+/g, '_')     // Replace spaces with underscores
      .replace(/[^\w-_.]/g, ''); // Remove non-alphanumeric characters (keeping underscores, dashes, and periods)
  }
  
  
  const handleRedeemPromoCode = async () => {
    const trimmedCode = promoCode.trim();
    if (!trimmedCode) {
      setPromoCodeError('Please enter a valid promo code.');
      setPromoCodeMessage('');
      return;
    }
  
    setIsRedeeming(true);
    setPromoCodeError('');
    setPromoCodeMessage('');
  
    // Retrieve deviceId using the utility function
    const deviceId = getDeviceId();
  
    // Get the current user and their data
    const currentUser = auth.currentUser;
    const userUid = currentUser.uid;
    const userEmail = currentUser.email;
  
    // Retrieve learning goal and purchaseUid
    const userDocRef = doc(firestore, 'learningGoals', userUid);
    const docSnap = await getDoc(userDocRef);
    let learningGoal = docSnap.exists() ? docSnap.data().learningGoal || "" : "";
  
    if (!learningGoal) {
      setPromoCodeError('No learning goal found. Please set a learning goal before redeeming a promo code.');
      setPromoCodeMessage('');
      setIsRedeeming(false);
      return;
    }
  
    // Generate a unique purchaseUid (you can use your existing function)
    const currTimeStamp = new Date();
    const purchaseUid = createPromoPurchaseID(currTimeStamp, userUid, learningGoal, promoCode);
  
    try {
      // Pass the necessary data to the Cloud Function
      const response = await redeemPromoCodeFunction({
        code: trimmedCode,
        deviceId,
        userEmail,
        learningGoal,
        userUid,
        purchaseUid,
      });
  
      if (response.data.success) {
        setPromoCodeMessage(response.data.message);
        setPromoCodeError('');
  
        // Navigate to Quiz Generation Progress Page
        navigate('/quiz-generation-progress', { state: { source: 'promoCode' } });
  
        // Log successful redemption
        if (analytics) {
          logEvent(analytics, 'promo_code_redeemed', { promo_code: promoCode, user_uid: auth.currentUser.uid });
        }
      } else {
        // Handle unexpected success response structure
        setPromoCodeError('Unexpected response from server.');
        setPromoCodeMessage('');
      }
    } catch (error) {
      console.error('Error redeeming promo code:', error);
      let errorMsg = 'Failed to redeem promo code. Please try again.';
      if (error.code === 'already-exists') {
        errorMsg = 'You have already redeemed this promo code.';
      } else if (error.code === 'invalid-argument') {
        errorMsg = 'Invalid promo code.';
      } else if (error.code === 'failed-precondition') {
        errorMsg = 'Promo code is not active.';
      } else if (error.code === 'deadline-exceeded') {
        errorMsg = 'Promo code has expired.';
      } else if (error.code === 'resource-exhausted') {
        errorMsg = 'Promo code redemption limit reached.';
      }
      setPromoCodeError(errorMsg);
      setPromoCodeMessage('');
  
      // Log redemption failure
      if (analytics) {
        logEvent(analytics, 'promo_code_redeem_failed', { promo_code: promoCode, user_uid: auth.currentUser.uid, error_code: error.code });
      }
    } finally {
      setIsRedeeming(false);
    }
  };  
  

  const parseLearningPlan = (planString) => {
    console.log("planString:", planString);
    
    if (!planString || typeof planString !== 'string') return [];
    const milestones = planString.split('Milestone:').slice(1);

    return milestones.map((milestone) => {
      const [milestoneTitle, topics] = milestone.split('Topics:');
      return {
        milestone: milestoneTitle?.trim() || 'No Milestone', // fallback to prevent empty milestone names
        topics: topics ? topics.trim().split(', ') : []
      };
    });
  };

  // Store learning goal in localStorage and check for changes
  const handleLearningGoalChange = async (newLearningGoal) => {
    const storedGoal = localStorage.getItem('learningGoal');
    if (storedGoal !== newLearningGoal) {
      console.log("calling fetchEnhancedLearningOverview on newLearningGoal: " + newLearningGoal );
      localStorage.setItem('learningGoal', newLearningGoal);
      // No need to call any function here for fetching the enhanced plan separately
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  
    setIsLoading(false); // Reset isLoading when the component mounts
  
    // Check for canceled payment
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.get('canceled')) {
      openAlert(
        'Payment Canceled',
        'Your payment was canceled. You can try generating the quiz again.',
        [
          {
            label: 'OK',
            onClick: () => {
              // Close the alert modal
              closeAlert();
              // Remove 'canceled=true' from the URL without reloading the page
              navigate('/learning-focus', { replace: true });
            },
            variant: 'primary',
          },
        ]
      );
    }
  
    // Log page view
    if (analytics) {
      logEvent(analytics, 'page_view', {
        page_path: '/learning-focus',
        page_title: 'Learning Focus Page',
        page_location: window.location.href,
      });
    }
  }, [learningGoal, analytics, location.search]); // Added location.search
  

  // Removed the original useEffect that had fetchLearningData inside

  const handleDeleteLearningGoal = async () => {
    const currentUser = auth.currentUser;
    if (!currentUser) {
      console.error('No user is currently logged in.');
      return;
    }
  
    const learningGoalsRef = doc(firestore, 'learningGoals', currentUser.uid);
  
    try {
      console.log('Fetching document for user:', currentUser.uid);
      const docSnap = await getDoc(learningGoalsRef);
      if (docSnap.exists()) {
        const currentData = docSnap.data();
        const existingHistory = currentData.learningGoalHistory || [];
  
        // Only save the current learning goal into history if it doesn't already exist and is not empty
        const isDuplicate = existingHistory.some((historyItem) =>
          historyItem.learningGoal === currentData.learningGoal &&
          historyItem.learningPlan === currentData.learningPlan
        );
  
        if (!isDuplicate && currentData.learningGoal && currentData.learningGoal !== '') {
          const newHistoryItem = removeUndefinedFields({
            learningGoal: currentData.learningGoal,
            learningPlan: currentData.learningPlan,
            outputFilePath: currentData.outputFilePath,
            quizId: currentData.quizId,
            evaluationScore: currentData.evaluationScore,
            enhancedLearningPlan: currentData.enhancedLearningPlan,
            feedback: currentData.feedback,
            progressPercentage: currentData.progressPercentage,
            quizGenerationStatus: currentData.quizGenerationStatus,
            timestamp: currentData.timestamp || new Date().toISOString(),
          });
  
          const updatedHistory = [...existingHistory, newHistoryItem];
  
          console.log('Updated history:', updatedHistory);
  
          // Update Firestore with the new learning goal and move the old one to history
          await setDoc(
            learningGoalsRef,
            {
              learningGoal: '', // Reset learning goal
              learningPlan: '', // Reset learning plan
              feedback: '', 
              progressPercentage: 0, // Set to 0
              quizGenerationStatus: '', // Reset quiz generation status
              outputFilePath: '',
              quizId: '',
              timestamp: '',
              userEmail: '',
              userUid: '',
              userScreenName: '',
              evaluationScore: '',
              enhancedLearningPlan: '',
              learningGoalHistory: updatedHistory, // Add updated history
              triggerEvalGoalAndEnhance: false,
            },
            { merge: true }
          );
  
          console.log('Successfully updated the learning goal and moved it to history.');
          navigate('/landing');

          // Log learning goal deletion
          if (analytics) {
            logEvent(analytics, 'learning_goal_deleted', { previous_learning_goal: currentData.learningGoal });
          }
        } else {
          console.log('No changes to the learning goal, or duplicate entry found.');
           // Update Firestore with the new learning goal and move the old one to history
           await setDoc(
            learningGoalsRef,
            {
                learningGoal: '', // Reset learning goal
                learningPlan: '', // Reset learning plan
                feedback: '', 
                progressPercentage: 0, // Set to 0
                quizGenerationStatus: '', // Reset quiz generation status
                outputFilePath: '',
                quizId: '',
                timestamp: '',
                userEmail: '',
                userUid: '',
                userScreenName: '',
                evaluationScore: '',
                enhancedLearningPlan: '',
                learningGoalHistory: existingHistory, // Add updated history
                triggerEvalGoalAndEnhance: false,
            },
            { merge: true }
          );
  
          console.log('Successfully updated the learning goal and moved it to history.');
          navigate('/landing');

          // Log learning goal deletion
          if (analytics) {
            logEvent(analytics, 'learning_goal_deleted', { previous_learning_goal: currentData.learningGoal });
          }
        }
      } else {
        console.error('Document does not exist for user:', currentUser.uid);
      }
    } catch (error) {
      console.error('Error resetting learning goal:', error);
      alert('Failed to reset your learning goal. Please try again.');

      // Log learning goal deletion failure
      if (analytics) {
        logEvent(analytics, 'learning_goal_deletion_failure', { error_code: error.code, error_message: error.message });
      }
    }
  };
  
  const handleEditLearningGoal = async () => {
    const currentUser = auth.currentUser;
    if (!currentUser) {
      console.error('No user is currently logged in.');
      return;
    }
    console.log("TODO? -> Do an edit here or?");

    // Log edit learning goal attempt
    if (analytics) {
      logEvent(analytics, 'edit_learning_goal_attempt', { user_uid: currentUser.uid });
    }

    // Implement edit functionality as needed
  }

  const startPolling = () => {
    if (pollingIntervalRef.current || hasLoadedFromHistory.current) return;
  
    if (quizGenerationStatusRef.current || quizGenerationStatusRef.current === 'Completed') return;  // don't bother if we are generating a Quiz
  
    setIsPolling(true);
    setPollError(null);
    setRetryAvailable(false);
    setLearningPlan([]);
    setEnhancedLearningPlan([]);
  
    const POLL_INTERVAL = 5000; // 5 seconds
    const MAX_POLL_TIME = 60000; // 60 seconds
    const pollStartTimeRef = { current: Date.now() };
  
    const intervalId = setInterval(async () => {
      const currentUser = auth.currentUser;
      if (!currentUser) return;
  
      try {
        const userDocRef = doc(firestore, 'learningGoals', currentUser.uid);
        const docSnap = await getDoc(userDocRef);
  
        if (docSnap.exists() && !hasLoadedFromHistory.current) {
          const data = docSnap.data();
  
          if (data.quizGenerationStatus) {
            console.log("Set quizGenerationStatus to:", data.quizGenerationStatus);
            setQuizGenerationStatus(data.quizGenerationStatus);
          }
  
          // If learningPlan is ready
          if (data.learningPlan && data.learningPlan.trim() !== '') {
            setLearningPlan(parseLearningPlan(data.learningPlan));
            setEnhancedLearningPlan(data.enhancedLearningPlan || []);
            setIsPolling(false);
            clearInterval(intervalId);
            if (timeoutIdRef.current) {
              clearTimeout(timeoutIdRef.current);
              timeoutIdRef.current = null;
            }
            return;
          }
  
          if (data.quizGenerationStatus) {
            return;
          }
        }
  
        // Check if we've exceeded max poll time
        const elapsed = Date.now() - pollStartTimeRef.current;
        console.log("POLLING elapsed time: " + elapsed);
  
        if (elapsed > MAX_POLL_TIME && !quizGenerationStatusRef.current ) {
          console.log(" elapsed > MAX_POLL_TIME && !quizGenerationStatus: setPollError ")
          clearInterval(intervalId);
          setIsPolling(false);
          setPollError('- Timed out waiting for learning plan creation. A backend service might be down, Come back later and retry again.');
          setRetryAvailable(true);
        }
      } catch (error) {
        if (quizGenerationStatusRef.current || quizGenerationStatusRef.current === 'Completed' ||
             (learningPlan && learningPlan.length > 0)) {
          console.log(" Something weird, there is an error but we have valid data, so disable retry ")
          setIsPolling(false);
          setRetryAvailable(false);
          setPollError(null);
          return;
        }
        clearInterval(intervalId);
        setIsPolling(false);
        setPollError('Failed to fetch the learning plan. Please try again.');
        setRetryAvailable(true);
        console.error(error);
  
        if (analytics) {
          logEvent(analytics, 'learning_plan_fetch_error', {
            error_message: error.message,
          });
        }
      }
    }, POLL_INTERVAL);
  
    pollingIntervalRef.current = intervalId;
  
    // Timeout Management
    timeoutIdRef.current = setTimeout(() => {
      if (pollingIntervalRef.current && !quizGenerationStatusRef.current) {
        if (
          quizGenerationStatusRef.current === 'Completed' ||
          (learningPlanRef.current && learningPlanRef.current.length > 0)
        ) {
          console.log("Something weird: there is an error but we have valid data, so disable retry");
          setIsPolling(false);
          setRetryAvailable(false);
          setPollError(null);
          return;
        }
    
        console.log("Hitting Poll Error 1:");
        console.log("pollingIntervalRef.current:", pollingIntervalRef.current);
        console.log("quizGenerationStatusRef.current:", quizGenerationStatusRef.current);
        console.log("learningPlanRef.current:", learningPlanRef.current);
    
        clearInterval(pollingIntervalRef.current);
        pollingIntervalRef.current = null;
        setIsPolling(false);
        setPollError('Timed out: waiting for learning plan creation. A backend service might be down, Come back later and retry again.');
        setRetryAvailable(true);
      }
    }, MAX_POLL_TIME);
  
    // Cleanup
    const cleanup = () => {
      if (pollingIntervalRef.current) clearInterval(pollingIntervalRef.current);
      if (timeoutIdRef.current) clearTimeout(timeoutIdRef.current);
    };
  
    // Attach cleanup to window unload or component unmount
    window.addEventListener('beforeunload', cleanup);
    return () => {
      cleanup();
      window.removeEventListener('beforeunload', cleanup);
    };
  };
  

  // Adjusted Polling useEffect to use startPolling
  useEffect(() => {
    const cleanup = startPolling();
    return () => {
      if (cleanup) cleanup();
    };
  }, []); // Empty dependency array to run once on mount
  

  const handleRetry = async () => {
    // Logic to retry fetching or recreating the learning plan
    // For example, you might call your backend function again to create the plan.
    // After calling it, reset the state and start polling again.

    setPollError(null);
    setRetryAvailable(false);
    setLearningPlan([]);
    setEnhancedLearningPlan([]);
    
    if (!learningGoal) {
        console.log(" Something weird here, we should have a learningGoal on this page");
        return;
    }
    if (isEvaluationComplete){
      console.log(" Something weird here, evaluation complete");
      //for some reason it's set but let's continue.
    }
    if (learningPlan && learningPlan.length > 0){
      console.log(" Something weird here, we have a learningPlan already ");
      return;
    }

    // Retry creating the learningGoal:
    // Get the current user and their data
    const currentUser = auth.currentUser;
    const userUid = currentUser.uid;
    const userEmail = currentUser.email;
    try {
      const learningGoalsRef = doc(firestore, 'learningGoals', userUid);
      await setDoc(learningGoalsRef, {
        learningGoal: learningGoal,
        userEmail: userEmail,
        userUid: userUid,
        userScreenName: screenName || user.displayName,
        timestamp: serverTimestamp(),
        learningPlan: '', // Reset learning plan
        enhancedLearningPlan: '',
        feedback: '', // Reset feedback
        progressPercentage: 0, // Set to 0
        quizGenerationStatus: '', // Reset quiz generation status
        evaluationScore: '',
        triggerEvalGoalAndEnhance: true,
        }, { merge: true });

      // Log learning goal submission
      if (analytics) {
        logEvent(analytics, 'learning_goal_submit', { learning_goal: learningGoal, matched_quizzes_count: matchedQuizzes.length });
      }
      setIsPolling(true);
      startPolling();
    } catch (error) {
      console.error('Error saving learning goal:', error);
      alert('Failed to save your learning goal. Please try again later.');

      // Log learning goal submission failure
      if (analytics) {
        logEvent(analytics, 'learning_goal_submit_failure', { error_code: error.code, error_message: error.message });
      }
    }
    
  };

  const handleQuizLaunch = (quiz) => {
    // Log quiz launch event
    if (analytics) {
      logEvent(analytics, 'quiz_launch', { quiz_name: quiz.name, category: quiz.categoryName || 'Unknown' });
    }

    navigate(`/trivia-screen`, {
      state: {
        quizFile: quiz.jsonFile,
        quizName: quiz.name,
        quizType: 'randomQuiz10Timed',
        categoryName: quiz.categoryName || '',
      },
    });
  };

  const showQuizPage = () => {
    if (quizId) {
      navigate(`/quizpage?id=${quizId}`);

      // Log navigation to quiz page
      if (analytics) {
        logEvent(analytics, 'navigate_to_quiz_page', { quiz_id: quizId });
      }
    } else {
      console.error('quizId is not available');
    }
  };

  const removeUndefinedFields = (obj) => {
    return Object.fromEntries(Object.entries(obj).filter(([_, value]) => value !== undefined));
  };
  
  const handleLoadFromHistory = async (goalIndex) => {
    const currentUser = auth.currentUser;
    if (!currentUser) return;

    const learningGoalsRef = doc(firestore, 'learningGoals', currentUser.uid);
    const userUid = currentUser.uid;

    try {
      const docSnap = await getDoc(learningGoalsRef);
      if (docSnap.exists()) {
        const currentData = docSnap.data();
        const history = currentData.learningGoalHistory || [];

        // If there is an unsaved current learning goal, save it to history first
        if (currentData.learningGoal) {
          const newHistoryItem = removeUndefinedFields({
            learningGoal: currentData.learningGoal,
            learningPlan: currentData.learningPlan,
            outputFilePath: currentData.outputFilePath,
            quizId: currentData.quizId,
            evaluationScore: currentData.evaluationScore,
            enhancedLearningPlan: currentData.enhancedLearningPlan,
            feedback: currentData.feedback,
            progressPercentage: currentData.progressPercentage,
            quizGenerationStatus: currentData.quizGenerationStatus,
            userEmail: currentData.userEmail,
            userUid: currentData.userUid,
            userScreenName: currentData.userScreenName,
            timestamp: currentData.timestamp || new Date().toISOString(),
          });

          // Only add the new history item if it's not already in the history
          const isAlreadySaved = history.some(
            (item) => item.learningGoal === newHistoryItem.learningGoal
          );
          if (!isAlreadySaved) {
            const updatedHistory = [...history, newHistoryItem];

            // Update Firestore with the current learning goal saved into history
            await setDoc(
              learningGoalsRef,
              {
                learningGoalHistory: updatedHistory,
                triggerEvalGoalAndEnhance: false,
              },
              { merge: true }
            );
            console.log('Saved current learning goal to history before switching.');

            // Log saving to history
            if (analytics) {
              logEvent(analytics, 'learning_goal_saved_to_history', { learning_goal: currentData.learningGoal });
            }
          }
        }

        // Load the selected learning goal from history
        const selectedGoal = history[goalIndex];
        if (selectedGoal) {
          const sanitizedGoal = removeUndefinedFields({
            learningGoal: selectedGoal.learningGoal,
            learningPlan: selectedGoal.learningPlan,
            outputFilePath: selectedGoal.outputFilePath,
            quizId: selectedGoal.quizId,
            evaluationScore: selectedGoal.evaluationScore,
            enhancedLearningPlan: selectedGoal.enhancedLearningPlan,
            feedback: selectedGoal.feedback,
            progressPercentage: selectedGoal.progressPercentage,
            quizGenerationStatus: selectedGoal.quizGenerationStatus,
            timestamp: selectedGoal.timestamp || serverTimestamp(),
            triggerEvalGoalAndEnhance: false,
          });

          // Set the selected goal as the current learning goal in Firestore
          await setDoc(learningGoalsRef, sanitizedGoal, { merge: true });

          // Log loading from history
          if (analytics) {
            logEvent(analytics, 'learning_goal_loaded_from_history', { learning_goal: sanitizedGoal.learningGoal });
          }

          // Navigate to the updated learning focus page
          navigate('/learning-focus');

          // Log navigation to learning focus
          if (analytics) {
            logEvent(analytics, 'navigate_to_learning_focus_from_history', { learning_goal: sanitizedGoal.learningGoal });
          }
        }
      }
    } catch (error) {
      console.error('Error loading learning goal from history:', error);
      alert('Failed to load the selected learning goal.');

      // Log loading from history failure
      if (analytics) {
        logEvent(analytics, 'learning_goal_load_from_history_failure', { error_code: error.code, error_message: error.message });
      }
    }
  };
  
  // Function to handle deleting a history entry
  const handleDeleteHistoryItem = async (goalIndex) => {
    const currentUser = auth.currentUser;
    if (!currentUser) {
      console.error('No user is currently logged in.');
      return;
    }
  
    const learningGoalsRef = doc(firestore, 'learningGoals', currentUser.uid);
  
    try {
      const docSnap = await getDoc(learningGoalsRef);
      if (docSnap.exists()) {
        const currentData = docSnap.data();
        const existingHistory = currentData.learningGoalHistory || [];

        // Remove the selected goal from history
        const updatedHistory = existingHistory.filter((_, index) => index !== goalIndex);

        // Update Firestore with the new history
        await setDoc(learningGoalsRef, {
          learningGoalHistory: updatedHistory,
          triggerEvalGoalAndEnhance: false,
        }, { merge: true });

        // Update local state to reflect the deletion
        setLearningGoalHistory(updatedHistory);

        console.log('Learning goal history updated successfully.');

        // Log history item deletion
        if (analytics) {
          logEvent(analytics, 'learning_goal_history_deleted', { user_uid: currentUser.uid, deleted_goal_index: goalIndex });
        }
      } else {
        console.error('No document found for user.');
      }
    } catch (error) {
      console.error('Error deleting learning goal:', error);
      alert('Failed to delete learning goal. Please try again.');

      // Log deletion failure
      if (analytics) {
        logEvent(analytics, 'learning_goal_deletion_failure', { error_code: error.code, error_message: error.message });
      }
    }
  };

  const renderLearningPlan = () => {
    if (isPolling && (!Array.isArray(learningPlan) || learningPlan.length === 0) && !pollError ) {
      return (
          <div className="flex flex-col items-center justify-center my-8">
              <LoadingSpinner />
              <p className="mt-4 text-gray-600">Creating your personalized learning plan...</p>
          </div>
      );
    }

    return learningPlan.map((milestone, index) => {
        // Find the matching enhanced milestone by comparing milestone titles
        const enhancedInfo = enhancedLearningPlan.find(enhancedMilestone => enhancedMilestone.milestone === milestone.milestone);
    
        return (
            <div key={index} className="mb-6 p-4 border-l-4 border-purple-500 bg-gray-50 rounded-lg shadow-sm text-left">
            <h3 className="text-lg font-bold">{milestone.milestone}</h3>
            
            {milestone.topics && milestone.topics.length > 0 && (
                <ul className="mt-2 text-sm text-gray-700 list-disc pl-5">
                {milestone.topics.map((topic, topicIndex) => (
                    <li key={topicIndex}>{topic}</li>
                ))}
                </ul>
            )}
    
            {/* Insert enhanced explanation and URL if it exists */}
            {enhancedInfo && (
                <>
                {enhancedInfo.explanation && (
                    <p className="mt-2 text-sm text-gray-700">
                    {enhancedInfo.explanation}
                    </p>
                )}
    
                {enhancedInfo.url && (
                    <a href={enhancedInfo.url} target="_blank" rel="noopener noreferrer" className="text-blue-600 underline">
                    {enhancedInfo.url.replace(/^https?:\/\//, '')}
                    </a>
                )}
                </>
            )}
            </div>
        );
      });
    };

    // Function to match quiz based on the learning goal
    useEffect(() => {
        const fetchUserQuizzes = async () => {
        const currentUser = auth.currentUser;
        if (!currentUser) return;

        try {
            const quizzesRef = collection(firestore, 'quizzesMetadata');
            const q = query(quizzesRef, where('userUid', '==', currentUser.uid));
            const querySnapshot = await getDocs(q);

            const quizzesList = querySnapshot.docs.map(doc => doc.data());
            setUserQuizzes(quizzesList); // Store quizzes in state
            console.log("Fetched quizzes:", quizzesList);
        } catch (error) {
            console.error('Error fetching user quizzes:', error);
        }
        };

        fetchUserQuizzes();
    }, []); // Empty dependency array ensures this runs once after component mounts
  

    const normalizeString = (str) => {
        return str.trim().toLowerCase().replace(/\s+/g, '_'); // Convert to lowercase, remove extra spaces, and replace spaces with underscores
    };
      
    const findQuizByLearningGoal = () => {
        const normalizedGoal = normalizeString(learningGoal);
        console.log("Checking normalized learning goal:", normalizedGoal);
        
        const matchingQuiz = userQuizzes.find(quiz => normalizeString(quiz.quizName) === normalizedGoal);
        if (matchingQuiz) {
            console.log('Matching quiz found:', matchingQuiz.quizId);
            setQuizId(matchingQuiz.quizId); // Set the quizId if a match is found

            // Log quiz match found
            if (analytics) {
              logEvent(analytics, 'matching_quiz_found', { quiz_id: matchingQuiz.quizId, quiz_name: matchingQuiz.quizName });
            }
        } else {
            console.log('No matching quiz found for learning goal:', learningGoal);
            
            // Log no matching quiz found
            if (analytics) {
              logEvent(analytics, 'no_matching_quiz_found', { learning_goal: learningGoal });
            }
        }
    };
      

    // Call findQuizByLearningGoal when the learning goal changes or when quizzes are loaded
    useEffect(() => {
      if (learningGoal && userQuizzes.length > 0) {
        findQuizByLearningGoal();
      }
    }, [learningGoal, userQuizzes]);

  return (
    <div className="min-h-screen bg-gray-100 text-gray-900 flex flex-col items-center">
      {/* Navbar */}
      <nav className="w-full text-white p-4 flex justify-between items-center" style={{ backgroundColor: '#9C27B0' }}>
        <div className="flex items-center">
          <FontAwesomeIcon icon={faHome} className="text-white text-2xl cursor-pointer" onClick={() => { 
            navigate('/landing');

            // Log navigation to landing page
            if (analytics) {
              logEvent(analytics, 'navigate_button_click', { destination: 'landing_page' });
            }
          }} />
        </div>
        <h1 className="text-xl font-bold">Your Learning Focus</h1>
        <div></div>
      </nav>
      
      {pollError && (!quizGenerationStatus || quizGenerationStatus !== 'Completed') && (
        <div className="my-8 p-4 bg-red-100 border border-red-400 text-red-700 rounded">
          <p>{pollError}</p>
          {retryAvailable && (
            <button
              onClick={handleRetry}
              className="mt-2 bg-blue-600 text-white p-2 rounded"
            >
              Retry
            </button>
          )}
        </div>
      )}

      <div className="flex items-center mb-12 bg-gray-200 rounded-lg shadow-lg p-4" style={{ marginTop: '10px' }}>
        {/* Close Icon */}
        <div className="flex items-center">
          <FaTimes
            className="text-gray-900 cursor-pointer hover:text-red-600 transition-colors"
            size={24}
            onClick={() => {
              handleDeleteLearningGoal();

              // Log delete learning goal click
              if (analytics) {
                logEvent(analytics, 'delete_learning_goal_click', { learning_goal: learningGoal });
              }
            }}
          />
        </div>

        {/* Divider */}
        <div className="mx-4 h-6 border-l-2 border-gray-300"></div>

        {/* Learning Goal Text */}
        <p
          className="flex-grow text-2xl md:text-4xl font-bold text-gray-900 p-1 cursor-pointer"
          onClick={() => {
            handleEditLearningGoal();

            // Log edit learning goal click
            if (analytics) {
              logEvent(analytics, 'edit_learning_goal_click', { learning_goal: learningGoal });
            }
          }}
        >
          {learningGoal}
        </p>
      </div>

      {feedback && typeof evaluationScore === 'number' && evaluationScore >= 0.65 && (
        <div className="p-1 bg-gray-100 rounded-lg border border-gray-300 -mt-8 mb-1">
          <p className="text-sm text-gray-600">{feedback}</p>
        </div>
      )}

      {feedback && typeof evaluationScore === 'number' && evaluationScore < 0.65 && (
        <div className="bg-yellow-100 p-1 rounded-lg shadow-lg">
          <p className="text-3xl font-bold text-red-700">{feedback}</p>
        </div>
      )}

       {/* Conditional banner based on quiz generation status */}
       <div className="w-full max-w-md mb-1">
        {quizGenerationStatus === 'Completed' ? (
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="text-xl font-bold mb-4 text-center">Your custom quiz is ready!</h3>
            <button
              onClick={() => {
                showQuizPage();

                // Log quiz page navigation
                if (analytics) {
                  logEvent(analytics, 'navigate_to_quiz_page_button_click', { quiz_id: quizId });
                }
              }}
              className="mt-4 w-full bg-blue-600 text-white p-4 rounded-lg text-lg font-bold transition-all hover:shadow-lg hover:bg-blue-700"
            >
              Take Your Quiz
            </button>
          </div>
        ) : quizGenerationStatus ? (
          <div className="bg-yellow-100 p-6 rounded-lg shadow-lg">
            <h3 className="text-xl font-bold mb-4 text-center">Quiz generation in progress...</h3>
            <p className="text-gray-700 text-center">Current status: {quizGenerationStatus}</p>
            <p className="text-gray-700 text-center">Quiz generation can take up to 1 hour.</p>
          </div>
        ) : (null) }
      </div>
      
      {/* Offer Section - Independent Conditional Rendering */}
      {isEvaluationComplete && !quizGenerationStatus && typeof evaluationScore === 'number' && evaluationScore >= 0.65 && (
        <div className="w-full max-w-md mb-8">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="text-xl font-bold mb-4 text-center">Generate customized quizzes for:</h3>
            <p className="text-lg font-bold mb-2 text-purple-700 text-center">{learningGoal}</p>
            <p className="text-gray-700 mb-4 text-center">
              Get 100s of quizzes generated for your learning focus in under 24 hours!
            </p>
            <p className="text-4xl font-bold text-green-700 mt-2 text-center">$9.99</p>
            <div className="flex flex-col space-y-4 mt-4">
              <button
                onClick={() => {
                  handleGenerateQuiz();

                  // Log generate quiz button click
                  if (analytics) {
                    logEvent(analytics, 'generate_quiz_button_click', { learning_goal: learningGoal });
                  }
                }}
                className={`w-full bg-green-600 text-white p-4 rounded-lg text-lg font-bold transition-all ${
                  isLoading ? 'opacity-50 cursor-not-allowed' : 'hover:shadow-lg hover:bg-green-700'
                }`}
                disabled={isLoading}
              >
                {isLoading ? 'Processing...' : 'Generate Quiz'}
              </button>

              {/* New "Use Promo Code" Button */}
              <button
                onClick={() => setShowPromoCodeModal(true)}
                className="w-full bg-blue-600 text-white p-4 rounded-lg text-lg font-bold transition-all hover:shadow-lg hover:bg-blue-700"
              >
                Use Promo Code
              </button>
            </div>
          </div>
        </div>
      )}


      {/* Standard Learning Plan */}
      <div className="w-full max-w-3xl mb-8">
        {renderLearningPlan()}
      </div>

      {/* Related quizzes section */}
      {matchedQuizzes.length > 0 && (
        <div className="bg-gray-50 p-6 rounded-lg border border-gray-300 w-full max-w-md mt-8">
          <p className="text-gray-700 mb-4">Free related quizzes:</p>
          {matchedQuizzes.map((quiz, index) => (
            <button
              key={index}
              className="bg-[#841584] text-white p-3 rounded-lg w-full mb-4 transition-all hover:bg-[#5f0f63] transform hover:scale-105"
              onClick={() => {
                handleQuizLaunch(quiz);

                // Log related quiz launch
                if (analytics) {
                  logEvent(analytics, 'related_quiz_launch', { quiz_name: quiz.name, category: quiz.categoryName || 'Unknown' });
                }
              }}
            >
              {quiz.name}
            </button>
          ))}
        </div>
      )}

      {/* History Section */}
      {learningGoalHistory && learningGoalHistory.length > 0 && (
        <div className="w-full max-w-md">
          <h3 className="text-lg font-bold mb-4">Previous Learning Goals</h3>
          <div className="flex flex-col gap-4">
            {learningGoalHistory.map((goal, index) => (
              <div key={index} className="flex justify-between items-center bg-[#841584] text-white p-4 rounded-lg">
                <button
                  className="font-bold flex-grow text-left text-lg"
                  onClick={() => {
                    handleLoadFromHistory(index);

                    // Log history selection
                    if (analytics) {
                      logEvent(analytics, 'learning_goal_history_selected', { selected_goal: goal.learningGoal });
                    }
                  }}
                >
                  {goal.learningGoal}
                </button>
                <div className="flex-shrink-0 ml-4" style={{ width: '24px', height: '24px' }}>
                  <FaTrash
                    className="text-white cursor-pointer"
                    size={20} // You can adjust this size to ensure consistency
                    onClick={() => {
                      handleDeleteHistoryItem(index);

                      // Log history item deletion
                      if (analytics) {
                        logEvent(analytics, 'learning_goal_history_delete_click', { learning_goal: goal.learningGoal });
                      }
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      

    {/* Promo Code Modal */}
    <PromoCodeModal
        isOpen={showPromoCodeModal}
        onClose={() => {
          setShowPromoCodeModal(false);
          setPromoCode('');
          setPromoCodeError('');
          setPromoCodeMessage('');
        }}
        onRedeem={{
          promoCode,
          setPromoCode,
          handleRedeem: handleRedeemPromoCode,
          error: promoCodeError,
          success: promoCodeMessage,
          isRedeeming,
        }}
        message="Enter promo code to redeem a free quiz."
     />

    </div>
  );
}

export default LearningFocusPage;




//<FaPen className="text-gray-600 cursor-pointer text-sm ml-2" onClick={handleEditLearningGoal} />


    /*
    // OLD CODE: in: const fetchLearningData = async () => {
    const currentUser = auth.currentUser;
    if (!currentUser) {
      alert('You must be logged in to access this page.');
      navigate('/');

      // Log unauthenticated access attempt
      if (analytics) {
        logEvent(analytics, 'unauthenticated_access_attempt', { from_page: '/learning-focus' });
      }

      return;
    }

    const userUid = currentUser.uid;
    */
// HomePage.js for Quiznect.com website
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { auth } from './firebase';
import { sendEmailVerification, signInWithEmailAndPassword, createUserWithEmailAndPassword, OAuthProvider, signInWithPopup } from 'firebase/auth';
import { doc, getDoc, setDoc } from 'firebase/firestore'; // Import Firestore functions
import { firestore } from './firebase'; 
import { FaGoogle, FaApple } from 'react-icons/fa'; // Import Google and Apple icons
import { logEvent } from 'firebase/analytics';
import { analytics } from './firebase'; // Ensure analytics is exported from firebase.js

function HomePage() {
  const [user, setUser] = useState(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  //const [isLogin, setIsLogin] = useState(true); // Toggle between login and signup
  const navigate = useNavigate();
  const location = useLocation(); 

  // Initialize isLogin based on navigation state
  const [isLogin, setIsLogin] = useState(() => {
    if (location.state && location.state.mode === 'signup') {
      return false; // Set to Sign Up mode
    }
    return true; // Default to Log In mode
  });

  useEffect(() => {
    if (analytics) {
      logEvent(analytics, 'page_view', {
        page_path: '/home',
        page_title: 'Home Page',
        page_location: window.location.href,
      });
    }
  }, [analytics]);

  useEffect(() => {
    const storedEmail = localStorage.getItem('email');
    const storedPassword = localStorage.getItem('password');
    
    if (storedEmail && storedPassword) {
      setEmail(storedEmail);
      setPassword(storedPassword);
      setRememberMe(true);
    }
  }, []);

  useEffect(() => {
    if (user) {
      if (user.emailVerified) {
        navigate('/landing'); // Navigate to LandingPage after successful login/signup if verified
      } else {
        alert('Please verify your email before proceeding.');
      }
    }
  }, [user, navigate]);

  // Listen for Enter key press and trigger login/signup
  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === 'Enter') {
        handleAuthAction(); // Call the login or signup function when Enter is pressed
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [email, password, isLogin]);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleRememberMeChange = (e) => {
    setRememberMe(e.target.checked);
  };

  const handleAuthAction = async () => {
    try {
      let userCredential;

      if (isLogin) {
        logEvent(analytics, 'login_attempt', { method: 'email' });
        // Log in the user
        userCredential = await signInWithEmailAndPassword(auth, email, password);
        setUser(userCredential.user);
        console.log('Login successful:', userCredential.user);
        logEvent(analytics, 'login_success', { method: 'email' });
        if (!userCredential.user.emailVerified) {
          alert('Please verify your email before accessing the app.');
          return;
        }

        // Check if the user has a screen name
        const userDoc = await getDoc(doc(firestore, 'users', userCredential.user.uid));
        if (userDoc.exists() && userDoc.data().screenName) {
          navigate('/landing'); // User has a screen name, navigate to landing page
          logEvent(analytics, 'navigate_to_landing');
        } else {
          navigate('/create-screen-name', { state: { uid: userCredential.user.uid, email } }); // No screen name, redirect to setup
          logEvent(analytics, 'navigate_to_create_screen_name');
        }

      } else {
        logEvent(analytics, 'signup_attempt', { method: 'email' });
        // Sign up the user
        userCredential = await createUserWithEmailAndPassword(auth, email, password);
        setUser(userCredential.user);
        console.log('Signup successful:', userCredential.user);
        logEvent(analytics, 'signup_success', { method: 'email' });
        // Send email verification
        await sendEmailVerification(userCredential.user);
        //alert('A verification email has been sent to your email address. Please verify to continue.');

        // After successful signup and email verification sent
        navigate('/email-verification', { state: { uid: userCredential.user.uid, email } });
        logEvent(analytics, 'navigate_to_email-verification');
      }

      // Handle Remember Me functionality
      if (rememberMe) {
        localStorage.setItem('email', email);
        localStorage.setItem('password', password);
      } else {
        localStorage.removeItem('email');
        localStorage.removeItem('password');
      }

    } catch (error) {
      console.error('Error during authentication:', error);
      if (isLogin) {
        // Log login failure
        logEvent(analytics, 'login_failure', { method: 'email', error_code: error.code });
      } else {
        // Log signup failure
        logEvent(analytics, 'signup_failure', { method: 'email', error_code: error.code });
      }
      alert(error.message);
    }
  };

  const toggleAuthMode = () => {
    setIsLogin((prev) => !prev);
  };

  const signInWithGoogle = async () => {
    const provider = new OAuthProvider('google.com');
    try {
      logEvent(analytics, 'social_login_attempt', { provider: 'google' });

      const result = await signInWithPopup(auth, provider);
      setUser(result.user);
      logEvent(analytics, 'social_login_success', { provider: 'google' });
      console.log('Google login successful:', result.user);
    } catch (error) {
      console.error('Error during Google login:', error);
      logEvent(analytics, 'social_login_failure', { provider: 'google', error_code: error.code });
      alert(error.message);

    }
  };

  return (
    <div className="min-h-screen text-purple-700 flex flex-col items-center px-4 sm:px-6 lg:px-8 bg-white">
      {/* Hero Section */}
      <div className="mt-10 text-center max-w-4xl">
        <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold leading-tight" style={{ textShadow: '1px 1px 4px rgba(0, 0, 0, 0.3)' }}>
          Create customized Quizzes to learn anything.
        </h1>
        <p className="mt-4 text-base sm:text-lg text-purple-900 max-w-2xl mx-auto">
          Get personalized learning guides and quiz materials on any topic, powered by AI. Start now!
        </p>
      </div>

      {/* Call to Action and Authentication */}
      <div className="mt-7 text-white p-6 rounded-lg shadow-lg w-full max-w-md bg-[#841584]">
        <input
          id="email-input"
          type="email"
          placeholder="Type your email..."
          value={email}
          onChange={handleEmailChange}
          className="w-full p-3 rounded-lg text-black mb-4 focus:outline-none focus:border-[#c084f5] focus:shadow-[0_0_5px_rgba(132,21,132,0.5)]"
        />
        <input
          type="password"
          placeholder="Type your password..."
          value={password}
          onChange={handlePasswordChange}
          className="w-full p-3 rounded-lg text-black mb-4 focus:outline-none focus:border-[#c084f5] focus:shadow-[0_0_5px_rgba(132,21,132,0.5)]"
        />

        {/* Remember Me Checkbox */}
        <div className="flex items-center mb-4">
          <input
            type="checkbox"
            checked={rememberMe}
            onChange={handleRememberMeChange}
            className="mr-2"
          />
          <label className="text-white text-sm">Remember Me</label>
        </div>

        <button
          onClick={handleAuthAction}
          className="w-full bg-gradient-to-r from-purple-500 to-purple-400 text-white p-3 rounded-lg text-lg font-bold mb-4 transition-colors duration-300 ease-in-out hover:bg-[#722c80]"
        >
          {isLogin ? 'Log In' : 'Sign Up'}
        </button>

        <button
          onClick={toggleAuthMode}
          className="w-full bg-gray-700 text-white p-3 rounded-lg mb-4 text-sm"
        >
          {isLogin ? 'Create an Account' : 'Already have an account? Log In'}
        </button>

        {isLogin && (
          <div className="text-center mt-4">
            <button 
              onClick={() => navigate('/reset-password')} 
              className="text-blue-200 hover:text-blue-400 text-sm underline"
            >
              Forgot Password?
            </button>
          </div>
        )}
        
        <div className="text-center mb-4 text-gray-200">or</div>

        {/* Google Login */}
        <button
          onClick={signInWithGoogle}
          className="w-full bg-white text-black p-3 rounded-lg flex justify-center items-center mt-3"
        >
          <FaGoogle className="w-5 h-5 mr-2" />
          Continue with Google
        </button>

        <p className="text-center mt-4 text-xs text-gray-200">
          If you already have an account, we'll log you in.
        </p>
      </div>

       {/* Disclaimer and Privacy Policy Button */}
       <div className="mt-5">
        <button
          onClick={() => navigate('/disclaimer')}
          className="text-sm text-blue-500 hover:underline"
        >
          Disclaimer and Privacy Policy
        </button>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 gap-3">
        
        <div className="mt-6">
          <a
            href="https://apps.apple.com/us/app/quiznect/id6503987749"
            target="_blank"
            rel="noopener noreferrer"
            className="p-3 rounded-lg bg-[#841584] font-bold text-white text-lg  inline-flex items-center"
          >
            <img
              src="/favicon-96x96.png"
              alt="Quiznect Icon"
              className="w-6 h-6 mr-3"
            />

            iOS App
          </a>
        </div>
      
        <div className="mt-6">
          <a
            href="https://play.google.com/store/apps/details?id=com.sevenhillsstudios.quiznect"
            target="_blank"
            rel="noopener noreferrer"
            className="p-3 rounded-lg bg-[#841584] font-bold text-white text-lg  inline-flex items-center"
          >
            <img
              src="/favicon-96x96.png"
              alt="Quiznect Icon"
              className="w-6 h-6 mr-3"
            />
            Android App
          </a>
        </div>

      </div>
      <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-3 gap-3">
        
        <div className="mt-6">
          <a
            href="https://www.youtube.com/@Quiznect-o8n"
            target="_blank"
            rel="noopener noreferrer"
            className="bg-[#841584] text-white p-3 rounded-lg text-lg font-bold inline-flex items-center hover:bg-red-700 transition-colors duration-300 ease-in-out"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              className="w-6 h-6 mr-3"
              viewBox="0 0 24 24"
            >
              <path d="M19.615 3.184A4.59 4.59 0 0 0 16.545 2H7.455c-1.202 0-2.353.44-3.212 1.184C3.268 4.019 2.928 5.12 2.817 6.416 2.673 8.141 2.673 10.224 2.673 12c0 1.776 0 3.86.144 5.584.111 1.297.451 2.398 1.426 3.232.859.745 2.01 1.184 3.212 1.184h9.09c1.202 0 2.353-.44 3.211-1.184.975-.834 1.315-1.935 1.427-3.232.143-1.724.143-3.808.143-5.584 0-1.776 0-3.859-.144-5.584-.111-1.297-.451-2.398-1.426-3.232ZM10.455 14.273V9.727L14.727 12 10.455 14.273Z" />
            </svg>
            YouTube Channel
          </a>
        </div>

        <div className="mt-6">
          <a
            href="https://www.facebook.com/profile.php?id=61569921943333"
            target="_blank"
            rel="noopener noreferrer"
            className="bg-[#841584] text-white p-3 rounded-lg text-lg font-bold inline-flex items-center hover:bg-blue-700 transition-colors duration-300 ease-in-out"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              className="w-6 h-6 mr-3"
              viewBox="0 0 24 24"
            >
              <path d="M22.675 0H1.325C.593 0 0 .593 0 1.325v21.351C0 23.407.593 24 1.325 24H12.82V14.708h-3.156v-3.626h3.156V8.413c0-3.12 1.905-4.816 4.686-4.816 1.33 0 2.472.099 2.806.143v3.25l-1.926.001c-1.51 0-1.802.717-1.802 1.768v2.318h3.601l-.469 3.626h-3.132V24h6.139C23.407 24 24 23.407 24 22.675V1.325C24 .593 23.407 0 22.675 0z" />
            </svg>
            Facebook Page
          </a>
        </div>

        <div className="mt-6">
          <a
            href="https://open.spotify.com/show/6GElpdQfkRi0YouLvg342c?si=ER4OaIJ9QvuvXO8L4gNEWQ"
            target="_blank"
            rel="noopener noreferrer"
            className="bg-[#841584] text-white p-3 rounded-lg text-lg font-bold inline-flex items-center hover:bg-green-700 transition-colors duration-300 ease-in-out"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              className="w-6 h-6 mr-3"
              viewBox="0 0 24 24"
            >
              <path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm5.205 17.439c-.24.36-.681.472-1.047.271-2.865-1.65-6.472-2.015-10.727-1.082-.413.093-.837-.15-.93-.565-.093-.413.15-.836.564-.93 4.706-1.083 8.75-.658 11.949 1.317.36.209.472.65.271 1.047zm1.322-3.052c-.298.467-.926.616-1.414.33-3.281-1.902-8.272-2.459-12.117-1.32-.53.15-1.08-.165-1.233-.681-.15-.53.165-1.08.681-1.233 4.296-1.238 9.83-.609 13.553 1.545.467.271.63.887.33 1.414zm.15-3.353c-.359.591-1.14.774-1.73.413-3.77-2.273-9.395-2.774-13.44-1.49-.682.21-1.438-.195-1.644-.877-.209-.682.195-1.438.877-1.644 4.687-1.393 10.83-.833 15.026 1.73.591.359.774 1.14.412 1.73z" />
            </svg>
            Listen on Spotify
          </a>
        </div>

      </div>


      {/* Example Gallery */}
      <div className="mt-5 w-full max-w-6xl">
        <h2 className="text-2xl font-bold mb-8 text-center">Explore What You Can Learn</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {[
            { 
              title: "I want to learn Python programming.", 
              description: "Get quizzes and resources to master Python from basics to advanced concepts.", 
              link: "https://quiznect.com/quizpage?id=20241215211049158025" 
            },
            { 
              title: "I want to improve my public speaking skills.", 
              description: "Receive tailored exercises and quizzes to boost your public speaking confidence.", 
              link: "https://quiznect.com/quizpage?id=20241215223754504374" 
            },
            { 
              title: "I want to master data analysis with Excel.", 
              description: "Learn how to perform complex data analysis with interactive quizzes and examples.", 
              link: "https://quiznect.com/quizpage?id=20241216014833657703" 
            },
            { 
              title: "I want to create effective marketing campaigns.", 
              description: "Discover strategies and quizzes to develop successful marketing campaigns.", 
              link: "https://quiznect.com/quizpage?id=20241203010510919642" 
            },
            { 
              title: "I want to prepare for a job interview in tech.", 
              description: "Get quizzes and interview prep materials tailored to the tech industry.", 
              link: "https://quiznect.com/quizpage?id=20241216023213901493" 
            },
            { 
              title: "I want to learn wilderness survival.", 
              description: "Take quizzes to test if you know what it takes to survive in extreme conditions.", 
              link: "https://quiznect.com/quizpage?id=20241216034723787921" 
            },
          ].map((item, index) => (
            <a
              key={index}
              href={item.link}
              target="_blank"
              rel="noopener noreferrer"
              className="block p-6 rounded-lg shadow bg-[#841584] hover:shadow-lg transition-shadow duration-300 ease-in-out text-white"
            >
              <h3 className="text-lg font-bold mb-2">{item.title}</h3>
              <p className="text-sm">{item.description}</p>
            </a>
          ))}
        </div>
      </div>



    </div>
  );
}

export default HomePage;




      {/* Apple Login  - Disabled for now: */}
        {/*

            const signInWithApple = async () => {
    const provider = new OAuthProvider('apple.com');
    try {
      const result = await signInWithPopup(auth, provider);
      setUser(result.user);
      console.log('Apple login successful:', result.user);
    } catch (error) {
      console.error('Error during Apple login:', error);
    }
  };


        <button
          onClick={signInWithApple}
          className="mt-2 w-full bg-white text-black p-3 rounded-lg flex justify-center items-center auth-buttons"
        >
          <FaApple className="w-6 h-6 mr-2" />
          Continue with Apple
        </button>
          */}
import React, { useState, useEffect } from 'react';
import { auth } from './firebase'; // Ensure this is your initialized firebase auth
import { sendEmailVerification } from 'firebase/auth';
import { useNavigate, useLocation } from 'react-router-dom';

function EmailVerificationPage() {
  const [isVerifying, setIsVerifying] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  // Expecting uid and possibly email passed via navigate state
  // e.g., navigate('/email-verification', { state: { uid: userCredential.user.uid, email } });
  const { uid, email } = location.state || {};

  const handleCheckVerification = async () => {
    setIsVerifying(true);
    if (auth.currentUser) {
      await auth.currentUser.reload(); // Refresh user data
      if (auth.currentUser.emailVerified) {
        //alert('Your email is now verified. Proceeding to Screen Name setup...');
        navigate('/create-screen-name', { state: { uid, email } });
      } else {
        alert('Not Verified Yet. Please verify your email and try again.');
      }
    } else {
      alert('No user logged in. Please log in again.');
      navigate('/');
    }
    setIsVerifying(false);
  };

  const handleResendVerification = async () => {
    if (auth.currentUser && !auth.currentUser.emailVerified) {
      await sendEmailVerification(auth.currentUser);
      alert('Verification Email Resent. Please check your inbox again.');
    } else {
      alert('User is either not logged in or already verified.');
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-white px-4">
      <h1 className="text-2xl font-bold text-purple-700 mb-6">Verify Your Email</h1>
      <p className="text-center mb-4">
        A verification email was sent to <strong>{email || auth.currentUser?.email}</strong>.
        <br />Please check your inbox and click the link to verify your account.
      </p>

      <button
        onClick={handleCheckVerification}
        disabled={isVerifying}
        className="bg-purple-600 text-white font-bold py-2 px-4 rounded mb-4 hover:bg-purple-700 transition-colors"
      >
        I Have Verified My Email
      </button>

      <button
        onClick={handleResendVerification}
        className="bg-gray-400 text-white font-bold py-2 px-4 rounded hover:bg-gray-500 transition-colors"
      >
        Resend Verification Email
      </button>

      <button
        onClick={() => navigate('/')}
        className="mt-4 text-blue-600 hover:underline text-sm"
      >
        Back to Home
      </button>
    </div>
  );
}

export default EmailVerificationPage;

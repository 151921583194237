// ResetPasswordPage.js
import React, { useState } from 'react';
import { auth } from './firebase'; // Ensure this is your initialized auth instance
import { sendPasswordResetEmail } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

function ResetPasswordPage() {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  const handleResetPassword = async () => {
    if (!email) {
      alert('Please enter your email address.');
      return;
    }

    try {
      await sendPasswordResetEmail(auth, email);
      alert('A password reset email has been sent. Please check your inbox.');
      navigate('/'); // Return to homepage or wherever you want after sending
    } catch (error) {
      console.error('Error sending password reset email:', error);
      alert(error.message);
    }
  };

  return (
    <div className="min-h-screen text-purple-700 flex flex-col items-center justify-center bg-white px-4">
      <h1 className="text-3xl font-bold mb-8">Reset Password</h1>

      <input
        type="email"
        placeholder="Enter your email"
        className="p-3 rounded-lg text-black mb-4 w-full max-w-sm"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />

      <button
        onClick={handleResetPassword}
        className="bg-gradient-to-r from-purple-500 to-purple-400 text-white p-3 rounded-lg text-lg font-bold w-full max-w-sm hover:bg-[#722c80]"
      >
        Send Reset Email
      </button>

      <button
        onClick={() => navigate('/')}
        className="mt-4 text-blue-600 hover:underline text-sm"
      >
        Back to Home
      </button>
    </div>
  );
}

export default ResetPasswordPage;

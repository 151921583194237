// MyQuizzesViewer.js
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { firestore, auth } from './firebase'; // Import Firestore and Auth instance
import {
  collection,
  query,
  where,
  orderBy,
  limit,
  startAfter,
  getDocs,
} from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import LoadingSpinner from './LoadingSpinner'; // Ensure this component exists
import debounce from 'lodash.debounce';

function MyQuizzesViewer() {
  const [quizzes, setQuizzes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lastDoc, setLastDoc] = useState(null); // For pagination
  const [hasMore, setHasMore] = useState(true); // Indicates if more quizzes can be fetched
  const [error, setError] = useState(null);

  const navigate = useNavigate();
  const PAGE_SIZE = 10; // Number of quizzes per fetch, adjust as needed

  // Reference to the fetch function to avoid re-creating it on every render
  const fetchQuizzes = useCallback(async () => {
    if (loading || !hasMore) {
      console.log('Fetch aborted: loading or no more quizzes');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const quizzesRef = collection(firestore, 'quizzesMetadata');
      let q;

      if (lastDoc) {
        q = query(
          quizzesRef,
          where('userId', '==', auth.currentUser.uid),
          orderBy('quizId', 'desc'),
          startAfter(lastDoc),
          limit(PAGE_SIZE)
        );
      } else {
        q = query(
          quizzesRef,
          where('userId', '==', auth.currentUser.uid),
          orderBy('quizId', 'desc'),
          limit(PAGE_SIZE)
        );
      }

      const snapshot = await getDocs(q);
      console.log('Fetched', snapshot.size, 'quizzes');

      if (snapshot.empty) {
        console.log('No more quizzes to fetch');
        setHasMore(false);
      } else {
        const fetchedQuizzes = snapshot.docs.map((doc) => doc.data());
        console.log('Fetched quizzes:', fetchedQuizzes);

        setQuizzes((prevQuizzes) => [...prevQuizzes, ...fetchedQuizzes]);
        setLastDoc(snapshot.docs[snapshot.docs.length - 1]);

        if (snapshot.size < PAGE_SIZE) {
          console.log('Reached end of quizzes');
          setHasMore(false);
        }
      }
    } catch (err) {
      console.error('Error fetching quizzes:', err);
      setError('Failed to load quizzes. Please try again.');
    } finally {
      setLoading(false);
    }
  }, [loading, hasMore, lastDoc]);

  // Initial fetch and when lastDoc changes
  useEffect(() => {
    fetchQuizzes();
  }, [fetchQuizzes]);

  // Infinite Scroll Handler
  useEffect(() => {
    const handleScroll = debounce(() => {
      if (loading || !hasMore) return;

      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      const windowHeight = window.innerHeight;
      const fullHeight = document.documentElement.offsetHeight;

      // Trigger fetch when user is within 500px of the bottom
      if (windowHeight + scrollTop >= fullHeight - 500) {
        console.log('Scrolled near bottom, fetching more quizzes');
        fetchQuizzes();
      }
    }, 200); // Adjust debounce delay as needed

    window.addEventListener('scroll', handleScroll);
    console.log('Attaching scroll listener');

    return () => {
      window.removeEventListener('scroll', handleScroll);
      console.log('Detaching scroll listener');
      handleScroll.cancel();
    };
  }, [loading, hasMore, fetchQuizzes]);

  // Handle quiz item click
  const handleQuizClick = (quizId) => {
    navigate(`/quizpage?id=${quizId}`);
  };

  const handleNavigateToCreateQuiz = () => {
    navigate('/landing'); // Ensure this route exists
  };

  // Check user authentication
  const currentUser = auth.currentUser;

  useEffect(() => {
    if (!currentUser) {
      // If user is not logged in, navigate to the login page
      navigate('/');
    }
  }, [currentUser, navigate]);

  if (loading && quizzes.length === 0) {
    // Show loading spinner only when initially loading
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-100">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100 text-gray-900 flex flex-col items-center">
      {/* Navbar */}
      <nav
        className="w-full text-white p-4 flex justify-between items-center"
        style={{ backgroundColor: '#9C27B0' }}
      >
        <div className="flex items-center">
          <FontAwesomeIcon
            icon={faHome}
            className="text-white text-2xl cursor-pointer"
            onClick={() => navigate('/landing')}
          />
        </div>
        <h1 className="text-xl font-bold">Your Quizzes</h1>
        <div></div>
      </nav>

      {/* Quizzes List */}
      <div className="w-full max-w-3xl mt-8 mb-8 bg-white p-6 rounded-lg shadow-lg text-left">
        <h2 className="text-2xl font-bold mb-6">Your Quizzes</h2>
        {quizzes.length === 0 ? (
          <div className="text-center">
            <p className="text-lg">You haven't created any quizzes yet.</p>
            <button
              className="mt-4 w-full bg-green-600 text-white p-4 rounded-lg text-lg font-bold transition-all hover:shadow-lg hover:bg-green-700"
              onClick={handleNavigateToCreateQuiz}
            >
              Enter a learning goal and Create a New Quiz
            </button>
          </div>
        ) : (
          <ul className="quiz-list">
            {quizzes.map((quiz) => (
              <li
                key={quiz.quizId}
                className="quiz-item bg-white p-6 mb-6 rounded-lg shadow-lg cursor-pointer hover:bg-gray-100 transition-all"
                onClick={() => handleQuizClick(quiz.quizId)}
              >
                <h3 className="text-xl font-semibold mb-2">
                  {quiz.quizName.replace(/_/g, ' ')}
                </h3>
                <p className="mb-2">
                  <strong>Topics:</strong> {quiz.tags.join(', ')}
                </p>
                {/* Optionally, add more quiz details here */}
              </li>
            ))}
          </ul>
        )}
        {loading && quizzes.length > 0 && <LoadingSpinner />}
        {error && (
          <p className="text-red-500 text-center mt-4">{error}</p>
        )}
        {!hasMore && quizzes.length > 0 && (
          <p className="text-center mt-4">You've reached the end of your quizzes.</p>
        )}
      </div>
    </div>
  );
}

export default MyQuizzesViewer;